
body {
  margin: 0;
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: calc(0.9em + 0.5vmin);
  background-color: rgb(24, 28, 39);
  overscroll-behavior: contain;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-display: swap;
}


:focus {
  outline:none;
}

* {
  box-sizing: border-box;
}

#root {
  overflow: hidden;
  width: 100vw;
}

@media (min-width: 769px) {
  main,
  #root {
    width: 100%;
  }
}

.suspenseSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
