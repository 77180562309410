@function resize($base) {
  $a: 0.9em;
  $b: 0.5vmin;
  @return calc(#{$base*$a} + #{$base*$b});
}

$darkblue: #261e4c;
$lightyellow: #ffefd9;
$yellow: #ffb448;
$midorange: #e57630;
$orange: #cb3919;
$red: #df0046;
$grey: #4c4c4c;

$gradient_lightyellow: linear-gradient(0deg, #ffb448, #e57630);
$gradient_yellow: linear-gradient(0deg, #ffb448, #cb3919);
$gradient_red: linear-gradient(0deg, #8f003d, #df0046);
$gradient_darkblue: linear-gradient(0deg, #181c27 30%, #261e4c 75%);
$gradient_darkblue_reverse: linear-gradient(0deg, #261e4c 40%, #181c27 100%);
$gradient_white: linear-gradient(150deg, #ffffff14 30%, transparent 30%);
$gradient_grey2: linear-gradient(160deg, #ffffff14 40%, transparent 50%);
$gradient_grey3: linear-gradient(140deg, #ffffff14 30%, transparent 30%);

$shadow_grey: inset 0 0 resize(0.625) grey;

$size_50: resize(50); //800px
$size_30: resize(30); //480px
$size_20: resize(20); //320px
$size_18: resize(18); //288px
$size_12_5: resize(12.5); //200px
$size_10: resize(10); //160px
$size_-10: resize(-10); //-160px
$size_9: resize(9); //144px
$size_7: resize(7); //112px
$size_6: resize(6); //96px
$size_5: resize(5); //80px
$size_4: resize(4); //64px
$size_3: resize(3); //48px
$size_-3: resize(-3); //-48px
$size_2_25: resize(2.25); //36px
$size_2: resize(2); //32px
$size_1_5: resize(1.5); //24px
$size_1_25: resize(1.25); //20px
$size_1: resize(1); //16px
$size_0_75: resize(0.75); //12px
$size_0_625: resize(0.625); //10px
$size_0_5: resize(0.5); //8px
$size_0_25: resize(0.25); //4px

$size_1px: calc(1px + 0.1vmin);
$border_y: resize(0.08) solid $yellow;
$border_ly: resize(0.08) solid $lightyellow;
$border_db: resize(0.08) solid $darkblue;
$border_g: resize(0.08) solid $grey;
$border_o: resize(0.08) solid $orange;
$border_w: resize(0.08) solid white;


