@import "./config/design.scss";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

main {
  width: 100vw;
  min-height: 100vh;
  /* min-width: 320px; */
  background-image: $gradient_grey3, $gradient_darkblue;
  background-size: resize(22.5) resize(33), cover;
  background-repeat: no-repeat, no-repeat;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.rc-color-picker-wrap {
  width: resize(1.5) !important;
  height: resize(1.5) !important;
  margin: 0 resize(0.25) !important;
}

.rc-color-picker-trigger {
  border: $border_y !important;
  padding: 0 !important;
  border-radius: resize(1) !important;
  user-select: none !important;
  width: 100% !important;
  height: 100% !important;
  box-shadow: none !important;
}

.react-tabs__tab-list {
  display: flex;
}

.react-tabs .react-pdf__Page__canvas {
  width: resize(7.5) !important;
}

.select__multi-value__label {
  color: $darkblue !important;
  font-size: 100% !important;
}

.select__multi-value__remove {
  border: $border_db;
  padding: 0 !important;
  border-radius: resize(0.625) !important;
  width: resize(0.875) !important;
  height: resize(0.875) !important;
  margin-left: resize(0.25);
  cursor: pointer
}

.select__indicators {
  display: none !important;
}

.rc-slider {
  padding: resize(0.875) 0;
}

.rc-slider-rail {
  background-color: $lightyellow;
}

.rc-slider-track {
  background-color: $yellow;
}

.rc-slider-handle {
  margin-top: resize(-0.44);
  width: resize(1.125);
  height: resize(1.125);
  border: $border_y;
  background-color: $yellow;
}

.rc-slider-handle:focus {
  box-shadow: none;
}

.rc-input-number-input-wrap input{
  background-color: transparent;
  border: none;
  font-size: resize(1);
  color: $darkblue;
}

input[type="range"] {
  height: resize(1.56);
  -webkit-appearance: none;
  margin: resize(0.625) 0;
  width: 100%;
  background-color: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: resize(0.19);
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: $yellow;
  border-radius: $size_1px;
  border: 0px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: $border_y;
  height: resize(0.81);
  width: resize(0.81);
  border-radius: resize(0.44);
  background: $yellow;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: resize(-0.31);
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background:$yellow;
}

::-webkit-scrollbar {
  // width: resize(1);
  width: 0;
  height: resize(1);
}
::-webkit-scrollbar-track {
  box-shadow: $shadow_grey;
  border-radius: resize(0.5);
  margin: resize(0.5) 0;
}
::-webkit-scrollbar-thumb {
  
  background: $gradient_white, $gradient_yellow;
  border: $border_y;
  border-radius: resize(0.5);
}
::-webkit-scrollbar-thumb:hover {
  background: $gradient_white, $gradient_red; 
}
:root { 
  scrollbar-width: none;
  scrollbar-color: $midorange $lightyellow;
} 